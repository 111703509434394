import React, { useState } from "react"
import { Button, Offcanvas, Form, Modal } from "react-bootstrap"
import { Link } from "gatsby"
import InfoScrollbar from '../UserInfoScroll'
import { createStaffToPay,getWindowsLocation,getDeviceCode } from '../../api/app.ts'

const TryFree = (props) => {
  const [show, setShow] = useState(false);

  const { setShowScroll, priceIds, cbUrl } = props

  const handleClose = () => {
    setShowScroll(true);
    setShow(false)
  };
  const handleShow = () => {
    setShowScroll(false);
    setShow(true)
  };

  const [validated, setValidated] = useState(false);

  const [emailSub, setEmailSub] = useState(true)

  const [email, setEmail] = useState('')


  const [priceId, setPriceId] = useState(priceIds.annually)

  const [showPop, setShowPop] = useState(false);
  const handlePopClose = () => setShowPop(false);

  const [popText, setPopText] = useState('')
  const [errPop, setErrPop] = useState(false)

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (!form.checkValidity()) {
      setValidated(true);
      return
    }
    const params = {
      path:getWindowsLocation(),
      emailSub,
      email,
      priceId,
      origin: getDeviceCode(),
      autoEmail: true,
      ...cbUrl,
    }
    const { data, success } = await createStaffToPay(params)
    if (success) {
      const { popup, url } = data
      url && window.open(data.url, '_self');
      if (popup) {
        setPopText(popup)
        setShowPop(true)
      }
    } else {
      setErrPop(true)
    }
  };

  const toCubo = () => {
    window.open('https://cubo.to', '_self')
  }

  return (
    <>
      <section className="tryFree" id="tryFree">
        <Button type="button" variant="primary" onClick={handleShow} className="btn" id='oneTryOuterBtn'>Try Free
          <img className="arrow" src="/assets/images/arrow.png" alt='try free' />
        </Button>
      </section>

      <Offcanvas show={show} onHide={handleClose} placement='bottom' className='tryFree-offcanvas'>
        <InfoScrollbar extraClass={"tryFree"} isFreeTrial={true} />

        <h1 className="tryFree_title">
          <span className="yellowBG">Try Free</span>
          <span> for 7 Days</span>
        </h1>
        <div className="tryFreeCheck">
          <div className="tryFreeCheck_item first">
            <Form.Check type='radio' id={priceIds.annually} key='1' checked={priceId===priceIds.annually} label='Annually' name='radio1' onChange={() => setPriceId(priceIds.annually)} className={priceId===priceIds.annually ? 'checked' : ''} />
            <div className="tryFreeCheck_text">🔥 Save 20%</div>
            <div className="tryFreeCheck_content">$12/month</div>
          </div>
          <div className="tryFreeCheck_item">
            <Form.Check type='radio' id={priceIds.monthly} key='2' checked={priceId===priceIds.monthly} label='Monthly' name='radio1' onChange={() => setPriceId(priceIds.monthly)} className={priceId===priceIds.monthly ? 'checked' : ''}  />
            <div className="tryFreeCheck_content">$15/month</div>
          </div>
        </div>


        <div className="tryFreeForm">
          <Form noValidate onSubmit={handleSubmit} validated={validated}> 
            <Form.Group className="tryFreeForm-input"  >
              <Form.Control required type="email" placeholder="Enter your email" value={email} onChange={e => setEmail(e.target.value)}   id='oneEmailInput'/>
              <Form.Control.Feedback type="invalid">
                Enter a valid email address
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit" id='oneTrySubmit'>Try Free
              <img className="arrow" src="/assets/images/arrow.png" alt='try free' />
            </Button>
          </Form>
        </div>

        <Form.Check className="tryFreeTips" type='checkbox' label='Get Daily Online Business Tips for Free.' defaultChecked onChange={(e) => setEmailSub(e.target.checked)} />
        
        <div className="tryFreeFooter">
        By clicking Try Free, you agree to the <span>
          <Link to="/termsofservice/">&nbsp;Terms of Service&nbsp;</Link>
        </span>
        and
        <span>
          <Link to="/privacypolicy/">&nbsp;Privacy Policy&nbsp;</Link>
        </span>
        </div>
      </Offcanvas>

      <Modal show={showPop} onHide={handlePopClose} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton />
        <Modal.Body>{popText}</Modal.Body>
        <Modal.Footer className="popFooter">
          <Button variant="primary" onClick={toCubo}>
            confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TryFree
