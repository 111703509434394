/* eslint-disable no-unused-expressions */
import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import { AllAnalysisTool,NoIndexMeta } from "../components/Layout/analytics-tool"
import InfoScrollbar from "../components/UserInfoScroll/index"
import TryFree from "../components/TryFreeComponents/TryFree"
import {
  addTikTokAnalytics1,
  addTikTokAnalytics2,
  addTikTokAnalytics3,
} from "../utils/analytics-landing"

const cameraBase64String =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHAAAABwCAMAAADxPgR5AAAAllBMVEUAAAD39/309P3u7vz19fzv7/v19fzl5fjk5Pri4vjr6//z8/z5+f719P309P35+f719fzu7vv6+v/8/P/u7vz5+f3w8Pvq6vrm5vni4vbn5//t7f/w7/tYVtb////5+f3PzvR1c91eXNfb2/ZkYtnEw/CioOh7ed5vbdu2te29vO6Qj+Pp6fnx8fupqOrj4veXluWBgOBKzWu5AAAAHXRSTlMA/uRYzp+OcEApBvbu7djSx7CkmpiBemNTNyAOvcDz03UAAAOWSURBVGjetJfpkqowEEaFccV1dJxxme5IiIAgLu//cneqbhK2gAHD+UUVlIf01x1irxHOeLRd9+2pRRCJNbX76+1o7PS64TDa2AQVEHszOpi2jYc21mIPx+Zsk+EcNZgPJ0Z0uxVqs9q9rftYYCMWH2/pfmxsjP3TWrfvYyv6+1a644BgCcs73+IgZBSAsjCIb2fPwhJkcGwR3qwkO/kuKHD9U0k6axqlMyjaoifU8IyKzoHTaPKWmOMUU3gBjU+YYzlpUE4rl8jZBS3cM8kVRbus3zldFII2YZRTfuv5vjCD50IjXA8zfOn4PjEleUBjHgmmfL72/WZ7hUELWLZ7fhusj/jQEp9k1qid3zWA1gTXTI6a/ekxeAPmafXqRyY+Cm9BM0FWzuPEQsEZ3uaMAmui9jnLrM+kcekohYO0nmCEtKqD+gA9CkagXl2Mx5mcBwaGYHI6ZsfqgpIAjBGQyqLu5S0fDOLLZewLwn6pYQw3Tr9wHkROwsAoLEFO/vRoI+cBhnkgx1aOhAfG8VSjsRDRumAcV7TjIvXtkBNBB0TI2UnhSiwwhA4IxRJX8itRs2cb3cXFV2OInGKC7uVuYQXW/aIM3E8Sv/gzyBly4bxi5i/4gov6lEiqpn/O/78jJ1Y9pkb9ivT2Py4oECNnnKuoRZXr01/j84qoFFIrV1NbORMuapHmyCIUKCdD7jYH5DwVC9RfYpxgtfCJnMOfcCQqCjnuqMWdz1oucCghajr6E27UDWChBuI1/fzT1V+pDY9Q8eFFTcQs1Av9NESHiPhbCumFoEKonn3iyCm0oJ0wuGIJqA5xLHvGaym8opbQk12zrdi48Q1qNvBtb82vbt0Kb/zWWh7X4m6FsTy82SL8boWBnIspvwq7FYb81rQn+pV1K2Ri+npiamm3Qiomvycf6lYIyPlXvZmYAAgDQRALEUR8wbf/5qxA2JjNeKkgEPLsM4cviG8pfmjwa4FffPxpwx9v/HvCP+B8iXGmSYx8EdUeSSLKIROXTpeJJiF87aoQdkn9YRSlvs/M3JtkZox2rV8Fu+Y1pPMpGFKr5W4nwXJ7Q4WlE0IFb2xybc3xGpvgwRAdfeHhHh1f4gEtHUHjITtdI+BFCV0F/V92Fa7zIhSWRSvZGKVzuVo9DjhQBo2IBX/48ZZ4AI8XUYoJYfkws7ggnQcVjA1DZuOeNQCt35HdmqDkdOy6RrBcRufrHg7wjT886gJyNoJumFEAAAAASUVORK5CYII="
const sectionOneImg =
  "https://a.storyblok.com/f/177206/1041x2427/4ba8ffad61/ad_section_1.png"
const sectionTwoImg =
  "https://a.storyblok.com/f/177206/1029x1017/d3ab009720/ad_section_2.png"
const sectionThreeImg =
  "https://a.storyblok.com/f/177206/1125x912/5263791979/ad_section_3.png"
const sectionFourImg =
  "https://a.storyblok.com/f/177206/951x936/877e80fd18/ad_section_4_v2.png"
const sectionFiveImg =
  "https://a.storyblok.com/f/177206/951x1813/65553f5948/meeting_hub_v1.png"
const sectionSixImg =
  "https://a.storyblok.com/f/177206/1059x900/1d172d3735/ad_section_6.png"

const userFeedBack = [
  {
    name: "Yassin Takun",
    avatar:
      "https://a.storyblok.com/f/177206/176x176/6c8cadd3be/avatar_1.png/m/",
    position: "Personal Coach",
    feedback:
      "It created opportunities to engage with my existing customers, onboard new ones, and educate them at the right time in the user journey. Real-time connection at its best.",
  },
  {
    name: "Steffi Kwo",
    avatar:
      "https://a.storyblok.com/f/177206/176x176/6326810e6c/steffi_v3.png/m/",
    position: "Marketing Manager",
    feedback:
      "A key value differentiator of Cubo was the fluidity in connecting and managing my clients with live chat experience that definitely contributed to my business growth.",
  },
  {
    name: "Riven Hansley",
    avatar:
      "https://a.storyblok.com/f/177206/102x102/ff74d35b2f/author-avatar5.png/m/",
    position: "UX and UI Designer",
    feedback:
      "Cubo encourages to focus on doing our best work and provides an environment to actively connect with your clients, in one unified solution.",
  },
]

const LandingOne = () => {

  const [showScroll, setShowScroll] = useState(true)
  const priceIds = {
    annually: "price_1OaCRNFWYsVi0GA5uJcA5aZJ",
    monthly: "price_1OaCMbFWYsVi0GA53zxauqx7",
  }

  const cbUrl = {
    cancelUrl: "https://cubo.to/landingone/",
    successUrl: "https://cubo.to/paysuccess/",
  }
  return (
    <>
      <NoIndexMeta/>
      <AllAnalysisTool />
      <div className="landing_one_wrapper">
        {showScroll && <InfoScrollbar extraClass={"scroll_on_top"}  isFreeTrial={true} />}
        <TryFree
          setShowScroll={setShowScroll}
          priceIds={priceIds}
          cbUrl={cbUrl}
        />
        <section className="section_one">
          <img
            loading="lazy"
            src={"/assets/icons/cubo_logo_white.png"}
            alt="brand-logo"
            width={120}
            height={40}
            className="brand_logo"
          />
          <h1 className="title normal_title_size ">
            The #1 Online Office that works for you
          </h1>
          <p className="description normal_description_size">
          Make your online office space as unique as you and instantly connect with your clients, prospects, and teams with one link.
          </p>
          <div className="feature_wrapper">
            <img src={sectionOneImg} alt="feature_img" />
            <div className="tapchat_animation">
              <div className="avatar">
                <img
                  src="https://a.storyblok.com/f/177206/384x384/4002880bd2/mobile_tapchat_man.png"
                  loading="lazy"
                  alt="tapchat_man"
                />
                <p>James</p>
                <div className="greenBorder" />
              </div>

              <div className="avatar">
                <img
                  src="https://a.storyblok.com/f/177206/384x384/b1b76bd41b/mobile_tapchat_girl.png"
                  loading="lazy"
                  alt="tapchat_girl"
                />
                <p>Brenda</p>
              </div>

              <div id="camera-animate-video">
                <div className="banner-office-big animated-amplification moveBiggest" />
                <div className="banner-office-inner animated-amplification moveBiggest delay" />
                <img src={cameraBase64String} loading="lazy" alt="camera" />
              </div>
            </div>
          </div>
        </section>
        <section className="section_two">
          <h1 className="title normal_title_size ">
            The new Zoom + Calendly + Slack + Linktree
          </h1>
          <p className="description normal_description_size">
            One link for all functions and endless connections. We eliminate the
            hassle of switching across so many tools.
          </p>
          <div className="img_wrapper">
            <img loading="lazy" src={sectionTwoImg} alt="feature_img" />
          </div>
        </section>
        <section className="section_three">
          <h1 className="title normal_title_size ">
            Loved by teams and individuals around the world
          </h1>
          <div className="img_wrapper">
            <img loading="lazy" src={sectionThreeImg} alt="feature_img" />
          </div>
        </section>
        <section className="section_four">
          <h1 className="title normal_title_size ">
            Modernize your online presence
          </h1>
          <p className="description normal_description_size">
            Let visitors see your online status, how lively your personal space
            is and bring them to naturally and seamlessly connect to you.
          </p>
          <div className="img_wrapper">
            <img loading="lazy" src={sectionFourImg} alt="feature_img" />
          </div>
        </section>
        <section className="section_five">
          <h1 className="title normal_title_size ">
            Say “Hi“ to a different kind of communication experience
          </h1>
          <p className="description normal_description_size">
            Meet in a more engaging and meaningful way in real time from
            anywhere with video conference call, chat messages and online
            meetings.
          </p>
          <div className="demonstrate">
            <img
              src="https://a.storyblok.com/f/177206/951x1813/65553f5948/meeting_hub_v1.png"
              loading="lazy"
              alt="meeting_hub"
              className="meeting_hub"
            />
            <div className="tapchat_animation">
              <div className="avatar">
                <img
                  src="https://a.storyblok.com/f/177206/384x384/4002880bd2/mobile_tapchat_man.png"
                  loading="lazy"
                  alt="tapchat_man"
                />
              </div>
              <div className="avatar">
                <img
                  src="https://a.storyblok.com/f/177206/384x384/b1b76bd41b/mobile_tapchat_girl.png"
                  loading="lazy"
                  alt="tapchat_girl"
                />
                <p>Brenda</p>
              </div>

              <div id="camera-animate-video">
                <div className="banner-office-big animated-amplification moveBiggest" />
                <div className="banner-office-inner animated-amplification moveBiggest delay" />
                <img src={cameraBase64String} loading="lazy" alt="camera" />
              </div>
            </div>
          </div>
        </section>
        <section className="section_six">
          <h1 className="title normal_title_size ">
            Raise your professional reputation to the next level
          </h1>
          <p className="description normal_description_size">
          Build credibility by promoting your socials, websites, videos, music, podcasts, documents, and more in the Link Hub.
          </p>
          <div className="img_wrapper">
            <img loading="lazy" src={sectionSixImg} alt="feature_img" />
          </div>
        </section>
        <section className="section_seven">
          {userFeedBack.map((user, i) => {
            return (
              <div className="user_feedback" key={`user_feedback_${i}`}>
                <div className="avatar">
                  <img
                    loading="lazy"
                    src={user.avatar}
                    alt="user_avatar"
                    width={58}
                    height={58}
                  />
                </div>
                <div className="name">{user.name}</div>
                <div className="position">{user.position}</div>
                <p className="feedback">{user.feedback}</p>
              </div>
            )
          })}
        </section>
      </div>
    </>
  )
}

export default LandingOne
